import axios from "axios";
import { API_URL_STAGING } from "../pages/constants/Statics";
import { decryptData } from "./Helpers";
import CryptoJS from "crypto-js";

const instance = axios.create({
  baseURL: API_URL_STAGING,
});

const secretKeyMain = "rmAdminSecretKey";

instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
const encryptedAccessToken = localStorage.getItem("accessToken");

if (encryptedAccessToken) {
  let access_token = CryptoJS.AES.decrypt(
    encryptedAccessToken,
    secretKeyMain
  ).toString(CryptoJS.enc.Utf8);
  const JWT_token = access_token;
  instance.defaults.headers.common["Authorization"] = `Bearer ${JWT_token}`;
}

export const updateAccessToken = (accessToken) => {
  instance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
};

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    // Do something with response data
    return response;
  },
  (error) => {
    // Do something with response error
    if (error.response && error.response.status === 401) {
      // Handle 401 error
      localStorage.removeItem("rainDataToken");
      localStorage.removeItem("accessToken");
      // localStorage.clear();
      window.location.href = "/";
    }
    // if (error.response && error.response.status === 404) {
    //   window.location.href = "/page-not-found";
    // }

    // Return any error not to be handled
    return Promise.reject(error);
  }
);

export default instance;
